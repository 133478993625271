import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postCard"

const Stories = ({ data: { latestPost } }) => {
  const lang = "en"

  return (
    <Layout lang={lang} translation="/pl/blog/">
      <SEO title="Stories" />

      <section className="stories-content global__padd">
        <div className="row">
          {latestPost.edges.map(({ node }, index) => {
            const isNew = index === 0

            return (
              <div
                className={`col-lg-${isNew ? "12" : "4"}${
                  !isNew ? " col-md-6" : ""
                }`}
                key={index}
              >
                <PostCard
                  to={node.uri}
                  image={node.featuredImage?.node.sourceUrl}
                  time={node.date.split("T")[0].split("-").reverse().join(".")}
                  title={node.title}
                  description={node.excerpt}
                  shareUrl={node.uri}
                  isHorizontal={isNew}
                  isNew={isNew}
                />
              </div>
            )
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Stories

export const query = graphql`
  query {
    latestPost: allWpPost(
      sort: { fields: date, order: DESC }
      filter: { language: { code: { eq: EN } } }
    ) {
      edges {
        node {
          uri
          date
          title
          excerpt
          featuredImage {
            node {
              sourceUrl
            }
          }
        }
      }
    }
  }
`
